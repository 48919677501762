var wipwrp = window.wipwrp || {};

wipwrp.aflivePresence = (function ($) {
	var self = {};

	var allowedData = {
		image: {
			types: ["jpg", "jpeg", "png", "webp"],
			sizeLimit: 4, //MB
		},
		video: {
			types: ["avi", "mob", "mpg", "mp4"],
			sizeLimit: 400, //MB
		},
	};

	var productAllowedData = {
		image: {
			types: ["jpg", "jpeg", "png", "webp"],
			sizeLimit: 14, //MB
		},
		application: {
			types: [
				// "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				// "vnd.ms-excel",
				"xls",
				"xlsx",
			],
			sizeLimit: 1, //MB
		},
	};

	var mediaInputs = $(".media-input");
	// var productMediaInputs = $(".product-media-input");
	var inputsUrl = $(".input_url");
	var submitButton = $("#submit_uploads");

	// seel product page
	var addProdBtns = $(".add-product");

	self.init = function () {
		console.log("af live presence INIT");
		wipwrp.afLive.initiateModal();
		handleMediaInput();
		handleProductMediaInput();
		onHandleSubmit();
		handleAddProduct();
	};

	function isBtnValid() {
		var isValid = false;
		// ------ We have two cases upload photo product page and presence page for multimedia ------ //
		if ($("body").data("type") == "sell-online") {
			var categories = $(".wrap-accordion");
			var urlInputs = $("#form-save-photos").find(".input_url");
			var validCategories = [];
			// ------ We have to check if urlInpus exists or not as user may be premium or not ------ //
			if (!!urlInputs && urlInputs.length > 0) {
				// ------ If user is not premium and has uploaded at least an image for a category that category turn valid only if have the excel file uploaded  ------ //
				categories.each(function () {
					var imageInputs = $(this).find(".input_url");
					var filledInputs = imageInputs.filter(function () {
						return this.value;
					});
					// console.log('filledInputs: ', filledInputs)
					// console.log('filledInputs.length%2==0: ', filledInputs.length%2, filledInputs.length%2==0)
					if (filledInputs.length > 0) {
						var excelInput = $(this).find("input[data-file-type='excel']");
						validCategories.push(
							filledInputs.length > 3 &&
								filledInputs.length % 2 == 0 &&
								!!excelInput.val()
						);
					}
				});
				// console.log('validCategories: ', validCategories)
				isValid =
					validCategories.length > 0 && validCategories.every((vCatg) => vCatg);
			} else {
				// ------ If user is premium should have at least uploaded one excel file  ------ //
				var excelInputs = $("#form-save-photos")
					.find("input[data-file-type='excel']")
					.filter(function () {
						return this.value;
					});
				// console.log("excelInputs no categories: ", excelInputs);
				isValid = excelInputs.length > 0;
			}
		} else {
			// ------ This flow is for multimedia presence page where should have been uploaded all three required files  ------ //
			var validInputs = inputsUrl.filter(function () {
				return this.value;
			});
			isValid = validInputs.length > 2;
		}
		console.log("isValid", isValid);
		return isValid;
	}

	function onHandleSubmit() {
		submitButton.click(function (e) {
			e.preventDefault();
			if (isBtnValid()) {
				$("#form-save-photos").submit();
				// alert("Submit")
			}
		});
	}

	function isValidExt(extAllowed, fileExt) {
		return extAllowed.includes(fileExt);
	}

	var uploadFile = (url, file, filename, inputId) => {
		var presignedUrl = url;
		var keyname = presignedUrl.split("?")[0];
		var messageDiv = $("p[data-input-id='" + inputId + "']");
		function updateUpload(perc) {
			if (perc == "") messageDiv.html("..... .");
			else {
				perc = parseInt(perc * 100);
				messageDiv.html(perc + "%");
			}
		}

		if (url) {
			// messageDiv.show();
			$.ajax({
				xhr: function () {
					var xhr = new window.XMLHttpRequest();
					xhr.upload.addEventListener(
						"progress",
						function (evt) {
							if (evt.lengthComputable) {
								var percentComplete = evt.loaded / evt.total;
								updateUpload(percentComplete);
							}
						},
						false
					);

					xhr.addEventListener(
						"progress",
						function (evt) {
							if (evt.lengthComputable) {
								var percentComplete = evt.loaded / evt.total;
								updateUpload(percentComplete);
							}
						},
						false
					);

					return xhr;
				},
				type: "PUT",
				url: presignedUrl,
				// Content type must much with the parameter you signed your URL with
				contentType: "binary/octet-stream",
				// this flag is important, if not set, it will try to send data as a form
				processData: false,
				// the actual file is sent raw
				cache: false,
				data: file,
				success: function (response) {
					// messageDiv.html("File uploaded correctly!");
					messageDiv.html(
						"<span>OK</span><span class='icon icon-checkmark-outline'></span>"
						// "<span>" +
						// 	filename +
						// 	"</span><span class='icon icon-checkmark-outline'></span>"
					);
					$("#" + inputId + "_url").val(keyname);
					if (isBtnValid()) {
						submitButton.removeClass("submit-disabled");
						submitButton.prop("disabled", "");
					} else {
						submitButton.addClass("submit-disabled");
						submitButton.prop("disabled", "disabled");
					}
				},
				error: function (error) {
					console.log("error upload", error);
					messageDiv.html(
						"<span>Upload failed</span><span class='icon icon-cross-alt'></span>"
					);
					$("#" + inputId + "_url").val("");
					$("#" + inputId).val("");
					if (isBtnValid()) {
						submitButton.removeClass("submit-disabled");
						submitButton.prop("disabled", "");
					} else {
						submitButton.addClass("submit-disabled");
						submitButton.prop("disabled", "disabled");
					}
				},
			});
		} else {
			$("#" + inputId).val("");
		}
		return false;
	};

	function showFilename(inputId, filename) {
		$("p[data-input-id='" + inputId + "']").html(
			"<span>OK</span><span class='icon icon-checkmark-outline'></span>"
		);
		if (isBtnValid()) {
			submitButton.removeClass("submit-disabled");
			submitButton.prop("disabled", "");
		} else {
			submitButton.addClass("submit-disabled");
			submitButton.prop("disabled", "disabled");
		}
	}

	function generateUrl(
		inputId,
		filename,
		file,
		type,
		productCode,
		categoryId,
		fotoCode
	) {
		var typeFile = inputId;
		if (!!type) {
			typeFile = type;
		}
		var prodCode = productCode || "";
		var catgId = categoryId || "";
		var fotoCode = fotoCode || "";
		var endpoint =
			"/piattaforma_digitale/getPresignedS3Url?filename=" +
			encodeURIComponent(filename) +
			"&type=" +
			typeFile +
			"&categoria-id=" +
			catgId +
			"&prodotto-cod=" +
			prodCode +
			"&foto-cod=" +
			fotoCode;

		$("p[data-input-id='" + inputId + "']").html("...");
		// console.log("endpoint", endpoint);
		$.ajax({
			url: endpoint,
			contentType: "application/json",
			type: "GET",
			cache: false,
			success: function (result) {
				// console.log("endpoint result: ", result);
				if (!result) {
					$("p[data-input-id='" + inputId + "']").html(
						"<span>Upload failed</span><span class='icon icon-cross-alt'></span>"
					);
					$("#" + inputId + "_url").val("");
					return;
				}
				uploadFile(result, file, filename, inputId);
			},
			error: function (error) {
				console.log("error endpoint: ", error);
			},
		});
	}

	function handleInput(
		inputId,
		file,
		allowed,
		callback,
		type,
		productCode,
		categoryId,
		fotoCode
	) {
		$("p[data-input-id='" + inputId + "']").html();
		var ext = file.name.split(".").pop();
		var mediaType = file.type;
		if (type == "excel") {
			mediaType = "application/" + ext;
		}
		// console.log("mediaType", mediaType);
		var splitedFileType = mediaType.split("/");
		var fileType = splitedFileType[0];
		var fileExt = splitedFileType[1];
		var extAllowed = allowed[fileType].types;
		var sizeLimit = allowed[fileType].sizeLimit;
		var filename = file.name.replace(/\s+/g, "");
		// console.log("fileType", fileType);
		// console.log("fileExt", fileExt);
		// console.log("extAllowed", extAllowed);
		// console.log("sizeLimit", sizeLimit);
		// console.log("file", file);
		// console.log("filename", filename);
		var size = file.size;
		var wantedSize = sizeLimit * 1024 * 1024;
		if (size < wantedSize && isValidExt(extAllowed, fileExt)) {
			callback(
				inputId,
				filename,
				file,
				type,
				productCode,
				categoryId,
				fotoCode
			);
		} else {
			$("p[data-input-id='" + inputId + "']").html(
				"<span class='icon icon-cross-alt'></span>"
			);
			alert(
				"Only files " +
					extAllowed.join(", ") +
					" and smaller than " +
					sizeLimit +
					" MB are allowed."
			);
		}
	}

	function handleMediaInput() {
		submitButton.addClass("submit-disabled");
		mediaInputs.each(function () {
			$(this).change(function () {
				var inputId = $(this).attr("id");
				var file = this.files[0];
				handleInput(inputId, file, allowedData, generateUrl);
			});
		});
	}

	function handleProductMediaInput() {
		$(".product-media-input").each(function () {
			$(this).change(function () {
				var file = this.files[0];
				var inputId = $(this).attr("id");
				var type = $(this).data("file-type");
				var productCode = $(this).data("prodotto-cod");
				var categoryId = $(this).data("categoria-id");
				var fotoCode = $(this).data("foto-cod");
				// console.log("productCode", productCode);
				// console.log("categoryId", categoryId);
				// console.log("type", type);
				if (type === "excel") {
					handleInput(inputId, file, productAllowedData, showFilename, type);
				} else {
					handleInput(
						inputId,
						file,
						productAllowedData,
						generateUrl,
						type,
						productCode,
						categoryId,
						fotoCode
					);
				}
			});
		});
	}

	function handleAddProduct() {
		addProdBtns.each(function () {
			$(this).click(function (e) {
				e.preventDefault();
				var category = $(this).data("category-btn");
				var categoryId = $(this).data("categoria-id");

				var uploadSection = $('*[data-upload-category="' + category + '"]');
				var allProducts = uploadSection.find(".category-product").length;
				var nextProdIndex = allProducts + 1;
				// console.log("allProducts", allProducts.length);
				// console.log("uploadSection", uploadSection);
				var newUploadRow =
					'<hr class="inner" />' +
					'<div class="category-product">' +
					'<div class="product-images-title">IMMAGINI PRODOTTO ' +
					nextProdIndex +
					"</div>" +
					'<div class="upload-images__content">' +
					'<div class="upload-images__column">' +
					'<div class="product-image-title"><strong>IMMAGINE 1</strong></div>' +
					'<div class="file-input"><div class="label-container"><label for="' +
					category +
					"-p" +
					nextProdIndex +
					'-1">Scegli file</label><p data-input-id="' +
					category +
					"-p" +
					nextProdIndex +
					'-1"> </p></div>' +
					'<input id="' +
					category +
					"-p" +
					nextProdIndex +
					'-1" class="product-media-input hidden-important" data-file-type="foto_product" data-categoria-id="' +
					categoryId +
					'" data-prodotto-cod="' +
					nextProdIndex +
					'" data-foto-cod="1" type="file" accept="image/png,image/jpeg, image/webp" />' +
					'<input name="categorie[' +
					category +
					"][p" +
					nextProdIndex +
					'][foto_1]" type="hidden" id="' +
					category +
					"-p" +
					nextProdIndex +
					'-1_url" class="input_url hidden-important" />' +
					"</div></div>" +
					'<div class="upload-images__column"><div class="product-image-title"><strong>IMMAGINE 2</strong></div><div class="file-input">' +
					'<div class="label-container"><label for="' +
					category +
					"-p" +
					nextProdIndex +
					'-2">Scegli file</label><p data-input-id="' +
					category +
					"-p" +
					nextProdIndex +
					'-2"> </p></div><input id="' +
					category +
					"-p" +
					nextProdIndex +
					'-2"' +
					'class="product-media-input hidden-important" data-file-type="foto_product" data-categoria-id="' +
					categoryId +
					'" data-prodotto-cod="' +
					nextProdIndex +
					'" data-foto-cod="2" type="file"accept="image/png,image/jpeg, image/webp" />' +
					'<input name="categorie[' +
					category +
					"][p" +
					nextProdIndex +
					'][foto_2]" type="hidden" id="' +
					category +
					"-p" +
					nextProdIndex +
					'-2_url" class="input_url hidden-important" />' +
					"</div></div></div></div>";
				uploadSection.append(newUploadRow);
				handleProductMediaInput();
			});
		});
	}

	return self;
})(jQuery);
